import { Download, showError } from "@action";
import {
  GroupReportDto2,
  GroupReportsClient,
  AssessmentReportStatus,
} from "@api";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { Table, TablePaginationConfig, Button, Modal, Popover } from "antd";
import { Space } from "lib";
import { MessageInstance } from "antd/lib/message";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ScoreSteps from "../Assessments/ScoreSteps";
import GenerateGroupReportModal from "./GenerateGroupReportModal";

type HeaderProps = {
  keywords: string | undefined | null;
  groupId: number;
  messageApi: MessageInstance;
  //   columns: any;
  // handleAction: () => Promise<void>;
};

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const AssessmentGroupReportTable = ({
  keywords,
  groupId,
  messageApi,
}: HeaderProps): JSX.Element => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "Assessment");

  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [modal, modalContextHolder] = Modal.useModal();
  const groupReportsClient = new GroupReportsClient();

  const [state, setState] = useState({
    items: [] as GroupReportDto2[],
    pagination: defaultPagination,
    loading: false,
  });

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "GroupReport")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await groupReportsClient.getGrouptReportsWithPagination(
      _keywords ?? keywords,
      groupId,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );
    setState({
      ...state,
      items: (res.items as GroupReportDto2[]) || ([] as GroupReportDto2[]),
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  function handleOpenModalGenerateGroupReport() {
    setCreateModalVisible(true);
  }

  const handleOnFinishGenerateGroupReport = () => {
    fetchItems();
  };

  const handleCancelGenerateGroupReport = () => {
    setCreateModalVisible(false);
  };

  async function handleDownloadReport(id: number) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await groupReportsClient.getTemplate(id);
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleArchiveReport(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmArchive" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await groupReportsClient.archiveGroupReport(id);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          //fetch
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  const columns: any = [
    {
      // title: "Group Report ID",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderGroupReportId" />,
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      // title: "Assessment Name",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderAssessmentName" />,
      dataIndex: "assessmentTemplateAlias",
      key: "assessmentTemplateAlias",
      sorter: true,
    },
    {
      // title: "Norm",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderNorm" />,
      dataIndex: "normTableName",
      key: "normTableName",
      sorter: true,
    },
    {
      // title: "Report Template",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderReportTemplate" />,
      dataIndex: "groupReportTemplateDisplayName",
      key: "groupReportTemplateDisplayName",
      sorter: true,
    },
    {
      // title: "Report Language",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderReportLanguage" />,
      dataIndex: "reportTemplatePreferredLanguageDisplayName",
      key: "reportTemplatePreferredLanguageDisplayName",
      sorter: true,
    },
    {
      // title: "Report Generation Time",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderReportGenerateTime" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Status",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderStatus" />,
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (s: AssessmentReportStatus) => <>{AssessmentReportStatus[s]}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupAssessmentGroupReportTableHeaderAction" />,
      key: "action",
      render: (text: any, r: GroupReportDto2) =>
        typeof r.id !== "undefined" ? (
          <Space>
            {auth.can(ACTION.Download, "GroupAssessmentReport") ? (
              <Button
                type="primary"
                disabled={r.status !== AssessmentReportStatus.Completed}
                onClick={() => handleDownloadReport(r.id as number)}
              >
                <FormattedMessage id="StaticDownloadButton" />
              </Button>
            ) : null}
            {r.hasScoreCheck && auth.can(ACTION.Read, "GroupScoreCheck") ? (
              <Popover
                content={<ScoreSteps id={r.id as number} isGroup={true} />}
                title="Score Check"
                trigger="click"
              >
                <Button type="primary">
                  <FormattedMessage id="AssessmentAssessmentTableScoreCheckButton" />
                </Button>
              </Popover>
            ) : null}
            {auth.can(ACTION.Archive, "GroupAssessmentReport") ? (
              <Button
                type="primary"
                // disabled={r.status !== AssessmentReportStatus.Completed}
                onClick={() => handleArchiveReport(r.id as number)}
              >
                <FormattedMessage id="StaticArchiveButton" />
              </Button>
            ) : null}
          </Space>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchItems();
  }, [keywords]);

  return (
    <>
      <Space justify="flex-end">
        {auth.can(ACTION.Download, "GenerateGroupReport") ? (
          <Button type="primary" onClick={handleOpenModalGenerateGroupReport}>
            <FormattedMessage id="ClientGroupAssessmentContentGenerateGroupReportButton" />
          </Button>
        ) : null}
      </Space>
      <Table
        scroll={{ x: "max-content" }}
        bordered
        dataSource={[...state.items]}
        columns={columns}
        rowKey={"id"}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
      />
      <GenerateGroupReportModal
        visible={createModalVisible}
        groupId={groupId}
        onCancel={handleCancelGenerateGroupReport}
        onFinish={handleOnFinishGenerateGroupReport}
      />
      {modalContextHolder}
    </>
  );
};

export default AssessmentGroupReportTable;
