import React, { useState, useEffect } from "react";
import { Space } from "lib";
import { Button, Form, message, Modal, ModalProps, Radio, Select, Input } from "antd";
import {
  ClientGroupGroupReportGenerationSettingDto,
  ClientGroupAssessmentTemplatesClient,
  ClientGroupGroupReportGenerationSettingsClient,
  GroupReportsClient,
  CreateGroupGroupReportBySettingCommand,
  CreateGroupGroupReportCommand,
  ClientGroupDto,
} from "@api";
import { showError } from "@action";
import { FormattedMessage } from "react-intl";
import { useForm } from "antd/lib/form/Form";
const { Option } = Select;
import { getIsAdmin, getIsClientAdmin } from "@services/auth";
import AsyncSelect from "lib/AsyncSelect";
import { useIntl } from "react-intl";

interface ICustomModal {
  groupId: number;
  onFinish?: any;
}

interface IModal extends ModalProps, ICustomModal {}

const GenerateGroupReportModal: React.FC<IModal> = ({
  groupId,
  onFinish,
  onCancel,
  ...rest
}) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = useForm();

  const groupReportsClient = new GroupReportsClient();
  const clientGroupAssessmentTemplatesClient =
    new ClientGroupAssessmentTemplatesClient();
  const clientGroupGroupReportGenerationSettingsClient =
    new ClientGroupGroupReportGenerationSettingsClient();

  const [selectedAssessmentId, setSelectedAssessmentId] = useState(
    undefined as number | undefined
  );
  const [loading, setLoading] = useState(false);
  const [isDisableScoringKeySet, setIsDisableScoringKeySet] = useState(true);
  const [isDisableSelectNormTable, setIsDisableSelectNormTable] =
    useState(true);
  const [isDisableSelectReportLanguage, setIsDisableSelectReportLanguage] =
    useState(true);

  const [reportLanguageData, setReportLanguageData] = useState([] as string[]);
  const [reportTemplateData, setReportTemplateData] = useState([] as string[]);
  const [reportScoringKeySetData, setReportScoringKeySetData] = useState(
    [] as string[]
  );
  const [reportNormData, setReportNormData] = useState([] as string[]);
  const [optionListPool, setOptionListPool] = useState({} as any);

  const [reportLanguage, setReportLanguage] = React.useState("");
  const [reportTemplate, setReportTemplate] = React.useState("");
  const [reportScoringKeySet, setReportScoringKeySet] = React.useState("");
  const [reportNorm, setReportNorm] = useState("");

  const [groupOptionListPool, setGroupOptionListPool] = useState([] as ClientGroupDto[]);
  const [groupOptionList, setGroupOptionList] = useState([] as number[]);
  const [reportTitle, setReportTitle] = useState("");
  const [powerpointFormat, setPowerpointFormat] = useState(false);
  
  const { formatMessage } = useIntl();
  const ReportGroupSelectTooltip = formatMessage({ id: "ReportGroupSelectTooltip" });

  const fetchItems = async () => {
    if (!selectedAssessmentId) {
      return;
    } else {
      resetStateValues(false);
    }
    setLoading(true);
    const res = await clientGroupGroupReportGenerationSettingsClient.get(
      selectedAssessmentId
    );
    groupby(res);
    
    const res2 = await groupReportsClient.getSupGroupByClientGroupAssessmentTemplateId(
      selectedAssessmentId
    );
    setGroupOptionList([]);
    form.setFieldsValue({
      groupList: undefined,
    });
    setGroupOptionListPool(res2);
    setLoading(false);
  };

  const groupby = (array: ClientGroupGroupReportGenerationSettingDto[]) => {
    const reportTemplateDisplayNameTemp: string[] = [];

    // tree object
    const result = array.reduce((group: any, setting) => {
      const {
        groupReportLanguage: reportLanguage,
        groupReportTemplateDisplayName: reportTemplateDisplayName,
        normTableName,
        scoringKeySetName,
      } = setting;
      if (
        reportLanguage &&
        reportTemplateDisplayName &&
        normTableName &&
        scoringKeySetName
      ) {
        if (!group[reportTemplateDisplayName]) {
          group[reportTemplateDisplayName] = {};

          reportTemplateDisplayNameTemp.push(reportTemplateDisplayName);
        }
        if (!group[reportTemplateDisplayName][reportLanguage]) {
          group[reportTemplateDisplayName][reportLanguage] = {};
        }

        if (getIsClientAdmin()) {
          if (
            !group[reportTemplateDisplayName][reportLanguage][normTableName]
          ) {
            group[reportTemplateDisplayName][reportLanguage][normTableName] =
              group[reportTemplateDisplayName][reportLanguage][normTableName] ??
              [];
            group[reportTemplateDisplayName][reportLanguage][
              normTableName
            ].push(setting);
          }
        } else {
          if (
            !group[reportTemplateDisplayName][reportLanguage][scoringKeySetName]
          ) {
            group[reportTemplateDisplayName][reportLanguage][
              scoringKeySetName
            ] = {};
          }

          if (
            !group[reportTemplateDisplayName][reportLanguage][
              scoringKeySetName
            ][normTableName]
          ) {
            group[reportTemplateDisplayName][reportLanguage][scoringKeySetName][
              normTableName
            ] =
              group[reportTemplateDisplayName][reportLanguage][
                scoringKeySetName
              ][normTableName] ?? [];
            group[reportTemplateDisplayName][reportLanguage][scoringKeySetName][
              normTableName
            ].push(setting);
          }
        }
      }
      return group;
    }, {});

    setReportTemplateData(reportTemplateDisplayNameTemp);
    setOptionListPool(result);
  };

  async function handleOk(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />,
      0
    );

    const selectedGenerationSetting: ClientGroupGroupReportGenerationSettingDto =
      getIsClientAdmin()
        ? optionListPool[reportTemplate][reportLanguage][reportNorm][0]
        : optionListPool[reportTemplate][reportLanguage][reportScoringKeySet][
            reportNorm
          ][0];

    try {
      if (!getIsClientAdmin()) {
        // CAL admin use
        await groupReportsClient.create(
          new CreateGroupGroupReportCommand({
            clientGroupAssessmentTemplateId: selectedAssessmentId as number,
            scoringKeySetId: selectedGenerationSetting.scoringKeySetId,
            normTableId: selectedGenerationSetting.normTableId,
            groupReportTemplateId:
              selectedGenerationSetting.groupReportTemplateId,
            powerpointFormat: powerpointFormat,
            clientGroupsIdList:groupOptionList,
            reportTitle:reportTitle,
          })
        );
      } else {
        await groupReportsClient.createBySetting(
          new CreateGroupGroupReportBySettingCommand({
            clientGroupGroupReportGenerationSettingId:
              selectedGenerationSetting.id,
            clientGroupsIdList:groupOptionList,
            reportTitle:reportTitle,
          })
        );
      }

      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      onFinish();
    } catch (err) {
      showError(err);
    } finally {
      hide();
      resetStateValues();
      onCancel && onCancel(e);
    }
  }

  async function handleCancel(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    resetStateValues();
    onCancel && onCancel(e);
  }

  async function resetStateValues(resetAll = true) {
    if (resetAll) {
      setSelectedAssessmentId(undefined);
      setReportTitle("");
    }
    form.resetFields();
    setIsDisableScoringKeySet(true);
    setIsDisableSelectNormTable(true);
    setIsDisableSelectReportLanguage(true);
  }

  async function handleSelectReportLang(value: string) {
    setReportLanguage(value);

    const temp = Object.keys(optionListPool[reportTemplate][value]);
    if (getIsClientAdmin()) {
      setReportNormData(temp);
      if (isDisableSelectNormTable) {
        setIsDisableSelectNormTable(false);
      } else {
        setReportNorm("");
        form.setFieldsValue({
          normTableName: undefined,
        });
      }
    } else {
      setReportScoringKeySetData(temp);
      if (isDisableScoringKeySet) {
        setIsDisableScoringKeySet(false);
      } else {
        setReportScoringKeySet("");
        setReportNorm("");
        form.setFieldsValue({
          scoringKeySetName: undefined,
          normTableName: undefined,
        });
      }
      setIsDisableSelectNormTable(true);
    }
  }

  async function handleSelectReportTemplate(value: string) {
    setReportTemplate(value);

    const temp = Object.keys(optionListPool[value]);
    setReportLanguageData(temp);
    if (isDisableSelectReportLanguage) {
      setIsDisableSelectReportLanguage(false);
    } else {
      setReportLanguage("");
      setReportScoringKeySet("");
      setReportNorm("");
      form.setFieldsValue({
        reportLanguage: undefined,
        scoringKeySetName: undefined,
        normTableName: undefined,
      });
      setIsDisableScoringKeySet(true);
      setIsDisableSelectNormTable(true);
    }
  }

  async function handleSelectScoringKeySet(value: string) {
    setReportScoringKeySet(value);

    const temp = Object.keys(
      optionListPool[reportTemplate][reportLanguage][value]
    );
    setReportNormData(temp);
    if (isDisableSelectNormTable) {
      setIsDisableSelectNormTable(false);
    } else {
      setReportNorm("");
      form.setFieldsValue({
        normTableName: undefined,
      });
    }
  }

  async function 
  handleSelectNormTable(value: string) {
    setReportNorm(value);
  }

  async function 
  handleSelectGroup(value: number[]) {
    setGroupOptionList(value);
  }

  async function handleReportTitleUpdate(value: string) {
    setReportTitle(value);
  }

  useEffect(() => {
    fetchItems();
  }, [selectedAssessmentId]);

  return (
    <Modal
      title={
        <FormattedMessage id="AssessmentAssessmentModalGenerateReportTitle" />
      }
      destroyOnClose={true}
      footer={[
        <Button key="cancel" type="default" onClick={handleCancel}>
          <FormattedMessage id="StaticCancelButton" />
        </Button>,
        <Button
          key="okay"
          type="primary"
          onClick={handleOk}
          disabled={reportNorm === ""}
        >
          <FormattedMessage id="AssessmentAssessmentModalGenerateReportButton" />
        </Button>,
      ]}
      {...rest}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Space direction="vertical" size="middle">
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
          <Form.Item
            required={true}
            label={
              <FormattedMessage id="AssessmentAssessmentStaticNameLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="AssessmentAssessmentStaticMissingNameRule" />
                ),
              },
            ]}
          >
            <AsyncSelect
              onUpdate={(pageNumber, pageSize) =>
                clientGroupAssessmentTemplatesClient.getClientGroupAssessmentTemplatesWithPagination(
                  undefined,
                  groupId,
                  true,
                  pageNumber,
                  pageSize,
                  undefined,
                  undefined
                )
              }
              onChange={(v: number) => {
                setSelectedAssessmentId(v);
              }}
              placeholder={
                <FormattedMessage id="AssessmentAssessmentStaticSelectPlaceholder" />
              }
              allowClear={false}
              labelName={"assessmentTemplateAlias"}
            ></AsyncSelect>
          </Form.Item>
          <Form.Item
            name="reportTemplateDisplayName"
            label={
              <FormattedMessage id="ReportReportTemplateStaticSelectLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingSelectRule" />
                ),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage id="ReportReportTemplateStaticSelectPlaceholder" />
              }
              onChange={handleSelectReportTemplate}
              style={{ width: "240px" }}
              disabled={selectedAssessmentId == undefined}
              loading={loading}
            >
              {reportTemplateData.map((x, i) => (
                <Option key={i} value={x}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="reportLanguage"
            label={<FormattedMessage id="LanguageLanguageStaticSelectLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="LanguageLanguageStaticMissingSelectRule" />
                ),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage id="LanguageLanguageStaticSelectPlaceholder" />
              }
              onChange={handleSelectReportLang}
              disabled={isDisableSelectReportLanguage}
              style={{ width: "240px" }}
              loading={loading}
            >
              {reportLanguageData.map((x, i) => (
                <Option key={i} value={x}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {getIsClientAdmin() == true ? null : (
            <Form.Item
              name="scoringKeySetName"
              label={
                <FormattedMessage id="ReportScoringKeySetStaticSelectLabel" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="ReportScoringKeySetStaticMissingSelectRule" />
                  ),
                },
              ]}
            >
              <Select
                placeholder={
                  <FormattedMessage id="ReportScoringKeySetStaticSelectPlaceholder" />
                }
                onChange={handleSelectScoringKeySet}
                disabled={isDisableScoringKeySet}
                loading={loading}
                style={{ width: "240px" }}
              >
                {reportScoringKeySetData.map((x, i) => (
                  <Option key={i} value={x}>
                    {x}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="normTableName"
            label={<FormattedMessage id="ReportNormStaticSelectLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportNormStaticMissingSelectRule" />
                ),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage id="ReportNormStaticSelectPlaceholder" />
              }
              onChange={handleSelectNormTable}
              disabled={isDisableSelectNormTable}
              style={{ width: "240px" }}
              loading={loading}
            >
              {reportNormData.map((x, i) => (
                <Option key={i} value={x}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="groupList"
            label={<FormattedMessage id="ReportGroupSelectLabel" />}
            tooltip= {ReportGroupSelectTooltip}
            // rules={[
            //   {
            //     required: true,
            //     message: (
            //       <FormattedMessage id="ReportGroupMissingSelectRule" />
            //     ),
            //   },
            // ]}
          >
            <Select
              mode="multiple" 
              allowClear
              placeholder={
                <FormattedMessage id="ReportGroupSelectPlaceholder" />
              }
              disabled={selectedAssessmentId == undefined}
              style={{ width: "240px" }}
              onChange={handleSelectGroup}
              loading={loading}
            >
              {groupOptionListPool.map((x, i) => (
                <Option key={i} value={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="reportTitle"
            label={<FormattedMessage id="ReportTitleLabel" />}
            rules={[
              {
                type: "string"
              }
            ]}
            initialValue=""
          >
          <Input 
          maxLength={50}
          onChange={(e) => {handleReportTitleUpdate(e.target.value)}} />
          </Form.Item>
          {getIsAdmin() == true && (
            <Form.Item
              name="powerpointFormat"
              label={
                <FormattedMessage id="ClientGroupAssesssmentGroupReportFileTypeLabel" />
              }
            >
              <Radio.Group
                value={powerpointFormat}
                onChange={(e) => {
                  setPowerpointFormat(e.target.value);
                }}
              >
                <Radio value={false}>
                  <FormattedMessage id="ClientGroupAssesssmentGroupReportPdfLabel" />
                </Radio>
                <Radio value={true}>
                  <FormattedMessage id="ClientGroupAssesssmentGroupReportPptLabel" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </Space>
      {messageContextHolder}
    </Modal>
  );
};

export default GenerateGroupReportModal;
