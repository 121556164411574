import React, { useEffect, useState } from "react";
import { Card, Space } from "lib";
import { Button, message, Form, Input, Modal, Popover } from "antd";
import { Layout } from "layout";
import {
  AssessmentsClient,
  AssessmentReportsClient,
  AssessmentReportDto,
  ReportTemplateBriefDto,
  NormTableBriefDto,
  ClientGroupReportGenerationSettingDto,
  ClientGroupReportGenerationSettingsClient,
  AssessmentReportStatus,
  ClientGroupAssessmentTemplatesClient,
  AssessmentTemplateBriefDto,
  ClientGroupDto,
  AssessmentDto2,
} from "@api";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import GenerateReportModal from "@components/Admin/Assessments/GenerateReportModal";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import ScoreSteps from "@components/Admin/Assessments/ScoreSteps";
import { navigate } from "gatsby";

import ClientGroupDetailMenu from "@components/Admin/ClientGroup/ClientGroupDetailMenu";
import Header from "@components/Admin/ClientGroup/Header";
import AssessmentReportMainTable from "@components/Admin/ClientGroup/AssessmentReportMainTable";
import AssessmentReportBulkGenerateTable from "@components/Admin/ClientGroup/AssessmentReportBulkGenerateTable";
import AssessmentReportBulkDownloadTable from "@components/Admin/ClientGroup/AssessmentReportBulkDownloadTable";
import AssessmentReportBulkSendTable from "@components/Admin/ClientGroup/AssessmentReportBulkSendTable";
import SendReportEmailModal from "@components/Admin/Assessments/SendReportEmailModal";
import AssessmentBulkReminderTable from "@components/Admin/ClientGroup/AssessmentBulkReminderTable";
import AssessmentGroupReportTable from "@components/Admin/ClientGroup/AssessmentGroupReportTable";

interface Params {
  id: number;
}
interface Props {
  params: Params;
}

const defaultSortOrder = "descend";

enum Mode {
  Main = 0,
  BulkGenerate = 1,
  BulkDownload = 2,
  BulkSend = 3,
  BulkReminder = 4,
  Group = 5,
}

const GroupLevelAssessmentPage = ({ params }: Props) => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "Assessment");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }
  const groupId = params.id;

  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [optionItems, setOptionItems] = useState({
    items: [] as AssessmentTemplateBriefDto[],
    loading: true,
  });

  const [keyOfGenerateReport, setKeyOfGenerateReport] = useState(
    undefined as undefined | number
  );

  const [keyOfArchiveReport, setKeyOfArchiveReport] = useState(
    undefined as undefined | number
  );

  const [modal, modalContextHolder] = Modal.useModal();
  const assessmentsClient = new AssessmentsClient();
  const assessmentReportsClient = new AssessmentReportsClient();
  const clientGroupReportGenerationSettingsClient =
    new ClientGroupReportGenerationSettingsClient();
  const clientGroupAssessmentTemplatesClient =
    new ClientGroupAssessmentTemplatesClient();
  const [mode, setMode] = useState(Mode.Main);

  const [stateGenerateReportModal, setStateGenerateReportModal] = useState({
    visible: false,
    assessmentId: [] as number[],
    optionList: [] as ClientGroupReportGenerationSettingDto[],
    loading: false,
  });
  const [stateSendEmailModal, setStateSendEmailModal] = useState({
    visible: false,
    clientGroup: undefined as ClientGroupDto | undefined,
    reportIds: [] as number[],
  });
  const [stateSendReminderEmailModal, setStateSendReminderEmailModal] =
    useState({
      visible: false,
      clientGroup: undefined as ClientGroupDto | undefined,
      ids: [] as number[],
    });

    const { formatMessage } = useIntl();
    const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  const fetchOptionItems = async () => {
    const res =
      await clientGroupAssessmentTemplatesClient.getClientGroupSelectedAssessmentTemplate(
        groupId
      );
    setOptionItems({
      items: res as AssessmentTemplateBriefDto[],
      loading: false,
    });
  };

  async function handleGenerateReport(
    id: number[],
    clientGroupAssessmentTemplateId: number
  ) {
    setStateGenerateReportModal({
      ...stateGenerateReportModal,
      assessmentId: id,
      optionList: [],
      loading: true,
    });

    try {
      const res = await clientGroupReportGenerationSettingsClient.get(
        clientGroupAssessmentTemplateId,
        false
      );
      setStateGenerateReportModal({
        assessmentId: id,
        optionList: res,
        visible: true,
        loading: false,
      });
    } catch (err) {
      showError(err);
    }
  }

  async function handleDownloadRawData(
    assessmentId: number,
    clientUserId: string
  ) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await assessmentsClient.export(
        assessmentId,
        undefined,
        undefined,
        clientUserId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadReport(id: number) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await assessmentReportsClient.getTemplate(id);
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleBulkDownloadReport(ids: number[]) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await assessmentReportsClient.getFileBulk(ids);
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  
  async function handleArchiveReport(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmArchive" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await assessmentReportsClient.archiveAssessmentReport(id);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          //fetch
          setKeyOfArchiveReport(id);
          console.log("fetch");
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleOpenModalSendReport(
    reportIds: number[],
    clientGroup: ClientGroupDto | undefined
  ) {
    setStateSendEmailModal({
      visible: true,
      reportIds,
      clientGroup,
    });
  }

  async function handleSendEmailRequest(data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareSendMessage" />,
      0
    );
    setStateSendEmailModal((prev) => ({ ...prev, visible: false }));

    const temp = { ...data, ids: stateSendEmailModal.reportIds };
    try {
      await assessmentReportsClient.sendAssessmentReport2(temp);
      messageApi.success(<FormattedMessage id="StaticSendSuccessMessage" />);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleCloseModalSendReport() {
    setStateSendEmailModal({ ...stateSendEmailModal, visible: false });
  }

  async function handleOpenModalSendReminder(
    ids: number[],
    clientGroup: ClientGroupDto | undefined
  ) {
    setStateSendReminderEmailModal({
      visible: true,
      ids,
      clientGroup,
    });
  }

  async function handleSendReminderRequest(data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareSendMessage" />,
      0
    );
    setStateSendReminderEmailModal((prev) => ({ ...prev, visible: false }));

    const temp = { ...data, ids: stateSendReminderEmailModal.ids };
    try {
      await assessmentsClient.sendAssessmentReminderBulk(temp);
      messageApi.success(<FormattedMessage id="StaticSendSuccessMessage" />);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleCloseModalSendReminder() {
    setStateSendReminderEmailModal({
      ...stateSendReminderEmailModal,
      visible: false,
    });
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
  }

  const columnsForReports = [
    {
      // title: "Full Name",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderFullName" />,
      dataIndex: ["assessmentClientUser", "fullName"],
      key: "Name",
    },
    {
      // title: "Login ID",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderLoginId" />,
      dataIndex: ["assessmentClientUser", "userName"],
      key: "userName",
    },
    {
      // title: "Report ID",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderReportId" />,
      dataIndex: "id",
      key: "id",
    },
    {
      // title: "Norm",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderNorm" />,
      dataIndex: "normTable",
      key: "normTable",
      render: (r: NormTableBriefDto) => <>{r.name}</>,
    },
    {
      // title: "Report Template",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderReportTemplate" />,
      dataIndex: "reportTemplate",
      key: "reportTemplate",
      render: (r: ReportTemplateBriefDto) => <>{r.name}</>,
    },
    {
      // title: "Report Language",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderReportLanguage" />,
      dataIndex: "reportTemplatePreferredLanguageDisplayName",
      key: "reportTemplatePreferredLanguageDisplayName",
    },
    {
      // title: "Report Generate Time",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderReportGenerateTime" />,
      dataIndex: "startedOn",
      key: "startedOn",
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Status",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderStatus" />,
      dataIndex: "status",
      key: "status",
      render: (r: AssessmentReportStatus) => <>{AssessmentReportStatus[r]}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupAssessmentAssessmentTableHeaderAction" />,
      key: "action",
      render: (text: any, r: AssessmentReportDto) => (
        <Space>
          {auth.can(ACTION.Download, "AssessmentReport") ? (
            <Button
              type="primary"
              onClick={() => handleDownloadReport(r.id as number)}
              disabled={r.status !== AssessmentReportStatus.Completed}
            >
              <FormattedMessage id="StaticDownloadButton" />
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "AssessmentReport") ? (
            <Button
              type="primary"
              onClick={() =>
                handleOpenModalSendReport(
                  [r.id as number],
                  r.assessmentClientUser?.clientGroup
                )
              }
              disabled={r.status !== AssessmentReportStatus.Completed}
            >
              <FormattedMessage id="AssessmentAssessmentTableSendEmailButton" />
            </Button>
          ) : null}
          {auth.can(ACTION.Read, "ScoreCheck") ? (
            <Popover
              content={<ScoreSteps id={r.id as number} />}
              title="Score Check"
              trigger="click"
            >
              <Button type="primary">
                <FormattedMessage id="AssessmentAssessmentTableScoreCheckButton" />
              </Button>
            </Popover>
          ) : null}
          
          {auth.can(ACTION.Archive, "AssessmentReport") ? (
            <Button
              type="primary"
              onClick={() => handleArchiveReport(r.id as number)}
              // disabled={r.status !== AssessmentReportStatus.Completed}
            >
              <FormattedMessage id="StaticArchiveButton" />
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const columns = [
    {
      // title: "Full Name",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderFullName" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      // title: "Login ID",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderLoginId" />,
      dataIndex: "clientUserUserName",
      key: "clientUserUserName",
      sorter: true,
    },
    {
      // title: "Email",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderEmail" />,
      dataIndex: "clientUserEmail",
      key: "clientUserEmail",
      sorter: true,
    },
    {
      // title: "Assessment Name",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderAssessmentName" />,
      dataIndex: "assessmentTemplateAlias",
      key: "assessmentTemplateAlias",
      sorter: true,
    },
    {
      // title: "Progress",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderProgress" />,
      dataIndex: "progress",
      key: "progress",
      sorter: true,
      render: (s: number) => <>{s}%</>,
    },
    {
      // title: "Time Added",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Time Completed",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderTimeCompleted" />,
      dataIndex: "completedOn",
      key: "completedOn",
      sorter: true,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupAssessmentReportTableHeaderAction" />,
      key: "action",
      render: (text: any, r: AssessmentDto2) =>
        typeof r.id !== "undefined" ? (
          <Space>
            {auth.can(ACTION.Download, "GenerateReport") ? (
              <Button
                type="primary"
                disabled={r.progress !== 100}
                onClick={() => {
                  handleGenerateReport(
                    [r.id as number],
                    r.clientGroupAssessmentTemplateId ?? 0
                  );
                }}
              >
                <FormattedMessage id="AssessmentAssessmentTableGenerateReportButton" />
              </Button>
            ) : null}
            {auth.can(ACTION.Download, "RawData") ? (
              <Button
                type="primary"
                disabled={r.progress !== 100}
                onClick={() =>
                  handleDownloadRawData(
                    r.id as number,
                    r.clientUserId as string
                  )
                }
              >
                <FormattedMessage id="AssessmentAssessmentTableRawDataButton" />
              </Button>
            ) : null}
            {auth.can(ACTION.Send, "ReminderEmail") ? (
              <Button
                type="primary"
                disabled={r.progress == 100}
                onClick={() => {
                  handleOpenModalSendReminder(
                    [r.id as number],
                    r.clientUser?.clientGroup
                  );
                }}
              >
                <FormattedMessage id="AssessmentAssessmentTableSendReminderButton" />
              </Button>
            ) : null}
          </Space>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchOptionItems();
  }, []);

  const keywordElement = (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={handleKeywordsSearch}
    >
      <Form.Item name="keywords">
        <Input placeholder={StaticKeywords} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="StaticSearchLabel" />
          </Button>
        )}
      </Form.Item>
    </Form>
  );

  return (
    <Layout title="ClientGroupAssessmentNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <Header id={groupId}></Header>
          <ClientGroupDetailMenu id={groupId} />
          {mode == Mode.Main && (
            <Space justify="space-between">
              <Space justify="flex-start">{keywordElement}</Space>
              <Space wrap>
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.BulkGenerate);
                  }}
                >
                  <FormattedMessage id="ClientGroupAssessmentContentBulkGenerateButton" />{" "}
                  <RightOutlined />
                </Button>
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.BulkDownload);
                  }}
                >
                  <FormattedMessage id="ClientGroupAssessmentContentBulkDownloadButton" />{" "}
                  <RightOutlined />
                </Button>
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.BulkSend);
                  }}
                >
                  <FormattedMessage id="ClientGroupAssessmentContentBulkSendButton" />{" "}
                  <RightOutlined />
                </Button>
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.BulkReminder);
                  }}
                >
                  <FormattedMessage id="ClientGroupAssessmentContentBulkSendReminderButton" />{" "}
                  <RightOutlined />
                </Button>
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.Group);
                  }}
                >
                  <FormattedMessage id="ClientGroupAssessmentContentGroupReportButton" />{" "}
                  <RightOutlined />
                </Button>
              </Space>
            </Space>
          )}
          {mode != Mode.Main && (
            <Space justify="space-between">
              <Space justify="flex-start">
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.Main);
                  }}
                >
                  <LeftOutlined />
                </Button>
                {mode == Mode.BulkGenerate && (
                  <FormattedMessage id="ClientGroupAssessmentContentBulkGenerateButton" />
                )}
                {mode == Mode.BulkDownload && (
                  <FormattedMessage id="ClientGroupAssessmentContentBulkDownloadButton" />
                )}
                {mode == Mode.BulkSend && (
                  <FormattedMessage id="ClientGroupAssessmentContentBulkSendButton" />
                )}
                {mode == Mode.BulkReminder && (
                  <FormattedMessage id="ClientGroupAssessmentContentBulkSendReminderButton" />
                )}
                {mode == Mode.Group && (
                  <FormattedMessage id="ClientGroupAssessmentContentGroupReportButton" />
                )}
              </Space>
              <Space wrap>{keywordElement}</Space>
            </Space>
          )}
          {mode == Mode.Main && (
            <AssessmentReportMainTable
              auth={auth}
              keywords={keywords}
              groupId={groupId}
              columns={columns}
              columnsForReports={columnsForReports.slice(2)}
              assessmentOptionItems={optionItems}
              keyOfGenerateReport={keyOfGenerateReport}
              keyOfArchiveReport={keyOfArchiveReport}
            />
          )}
          {mode == Mode.BulkGenerate && (
            <AssessmentReportBulkGenerateTable
              keywords={keywords}
              groupId={groupId}
              columns={columns.slice(0, -1)}
              assessmentOptionItems={optionItems}
              handleGenerateReport={handleGenerateReport}
            />
          )}
          {mode == Mode.BulkDownload && (
            <AssessmentReportBulkDownloadTable
              keywords={keywords}
              groupId={groupId}
              columns={columnsForReports.slice(0, -1)}
              handleAction={handleBulkDownloadReport}
            />
          )}
          {mode == Mode.BulkSend && (
            <AssessmentReportBulkSendTable
              keywords={keywords}
              groupId={groupId}
              columns={columnsForReports.slice(0, -1)}
              handleAction={handleOpenModalSendReport}
            />
          )}
          {mode == Mode.BulkReminder && (
            <AssessmentBulkReminderTable
              keywords={keywords}
              groupId={groupId}
              columns={columns.slice(0, -1)}
              handleAction={handleOpenModalSendReminder}
            />
          )}
          {mode == Mode.Group && (
            <AssessmentGroupReportTable
              keywords={keywords}
              groupId={groupId}
              messageApi={messageApi}
              // columns={columns.slice(0, -1)}
              // handleAction={handleOpenModalSendReminder}
            />
          )}
        </Space>
      </Card>
      <GenerateReportModal
        assessmentIds={stateGenerateReportModal.assessmentId}
        optionList={stateGenerateReportModal.optionList}
        loading={stateGenerateReportModal.loading}
        visible={stateGenerateReportModal.visible}
        onCancel={() =>
          setStateGenerateReportModal({
            ...stateGenerateReportModal,
            visible: false,
          })
        }
        onFinish={(ids: number[]) => {
          if (ids.length == 1) setKeyOfGenerateReport(ids[0]);
        }}
      />
      <SendReportEmailModal
        visible={stateSendEmailModal.visible}
        titleId={"AssessmentAssessmentModalSendEmailTitle"}
        clientGroup={stateSendEmailModal.clientGroup}
        handleAction={handleSendEmailRequest}
        handleCancelAction={handleCloseModalSendReport}
      />
      <SendReportEmailModal
        visible={stateSendReminderEmailModal.visible}
        titleId={"AssessmentAssessmentModalSendReminderTitle"}
        isTriggerTimeHidden={true}
        clientGroup={stateSendReminderEmailModal.clientGroup}
        handleAction={handleSendReminderRequest}
        handleCancelAction={handleCloseModalSendReminder}
      />
      {messageContextHolder}
      {modalContextHolder}
    </Layout>
  );
};

export default GroupLevelAssessmentPage;
