import { showError } from "@action";
import { AssessmentReportDto, AssessmentReportsClient } from "@api";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import {
  Space,
  Select,
  Table,
  TablePaginationConfig,
  Button,
  DatePicker,
} from "antd";
const { RangePicker } = DatePicker;
import { Key, TableRowSelection } from "antd/lib/table/interface";
const { Option } = Select;
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

type HeaderProps = {
  keywords: string | undefined | null;
  groupId: number;
  columns: any;
  handleAction: (ids: number[]) => Promise<void>;
};

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const AssessmentReportBulkDownloadTable = ({
  keywords,
  groupId,
  columns,
  handleAction,
}: HeaderProps): JSX.Element => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "Assessment");

  const assessmentReportsClient = new AssessmentReportsClient();

  const [state, setState] = useState({
    items: [] as AssessmentReportDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);

  const onSelectChange = (
    selectedRowKeys: Key[],
    selectedRows: AssessmentReportDto[]
  ) => {
    setSelectedRowKeys(selectedRowKeys);
    console.log(selectedRows);
  };

  const rowSelection: TableRowSelection<AssessmentReportDto> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [filter, setFilter] = useState({
    isCompleted: true as boolean | undefined,
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
  });

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Assessment")) return;

    setState((state) => ({
      ...state,
      loading: true,
    }));

    try {
      const res =
        await assessmentReportsClient.getAssessmentReportsWithPagination(
          _keywords,
          groupId,
          undefined,
          undefined,
          filter.isCompleted,
          filter.startDate,
          filter.endDate,
          params.pagination.current,
          params.pagination.pageSize,
          capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
          (params.sortOrder ?? defaultSortOrder) == "ascend"
        );
      setState((state) => ({
        ...state,
        items: res.items as AssessmentReportDto[],
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
      }));
    } catch (err) {
      showError(err);
    } finally {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  }

  const handleOpenModalBulkGenerate = () => {
    handleAction(selectedRowKeys as number[]);
  };

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  function handleChangeGenerateTime(values: any[] | null) {
    if (values) {
      setFilter((prev) => ({
        ...prev,
        startDate: values.length > 0 ? values[0] : undefined,
        endDate: values.length > 1 ? values[1] : undefined,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        startDate: undefined,
        endDate: undefined,
      }));
    }
  }

  useEffect(() => {
    fetchItems();
  }, [keywords, filter]);

  return (
    <>
      <Space>
        <Select
          placeholder="Report Generate Status"
          defaultValue={filter.isCompleted}
          disabled
          style={{ width: 200 }}
        >
          <Option value={true}>{"Report: Generated"}</Option>
          <Option value={false}>{"Report: Pending"}</Option>
        </Select>
        <RangePicker
          showTime
          allowEmpty={[true, true]}
          onChange={handleChangeGenerateTime}
        />
        {auth.can(ACTION.Download, "BulkDownloadReport") ? (
          <Button
            type="primary"
            onClick={handleOpenModalBulkGenerate}
            disabled={selectedRowKeys.length === 0}
          >
            <FormattedMessage id="ClientGroupAssessmentContentBulkDownloadButton" />
          </Button>
        ) : null}
      </Space>
      <Table
        scroll={{ x: "max-content" }}
        bordered
        // rowSelection={rowSelection}
        rowSelection={{
          type: "checkbox",
          preserveSelectedRowKeys: true,
          ...rowSelection,
        }}
        dataSource={[...state.items]}
        columns={columns}
        rowKey={"id"}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AssessmentReportBulkDownloadTable;
