import {
  AssessmentDto,
  AssessmentTemplateBriefDto,
  ClientGroupsClient,
} from "@api";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { Space, Select, Table, TablePaginationConfig, Button } from "antd";
import { Key, TableRowSelection } from "antd/lib/table/interface";
const { Option } = Select;
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

type HeaderProps = {
  keywords: string | undefined | null;
  groupId: number;
  columns: any;
  assessmentOptionItems: {
    items: AssessmentTemplateBriefDto[];
    loading: boolean;
  };
  handleGenerateReport: (
    id: number[],
    clientGroupAssessmentTemplateId: number
  ) => Promise<void>;
};

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const AssessmentReportBulkGenerateTable = ({
  keywords,
  groupId,
  columns,
  assessmentOptionItems,
  handleGenerateReport,
}: HeaderProps): JSX.Element => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "Assessment");

  const clientGroupsClient = new ClientGroupsClient();

  const [state, setState] = useState({
    items: [] as AssessmentDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);

  const onSelectChange = (
    selectedRowKeys: Key[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedRows: AssessmentDto[]
  ) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<AssessmentDto> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [filter, setFilter] = useState({
    isProgessComplete: true as boolean | undefined,
    assessmentTemplateId: undefined as number | undefined,
  });

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Assessment")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await clientGroupsClient.assessments(
      _keywords ?? keywords,
      groupId,
      filter.assessmentTemplateId,
      filter.isProgessComplete,
      undefined,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );
    setState({
      ...state,
      items: (res.items as AssessmentDto[]) || ([] as AssessmentDto[]),
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  const handleOpenModalBulkGenerate = () => {
    handleGenerateReport(
      selectedRowKeys as number[],
      state.items[0].clientGroupAssessmentTemplateId as number
    );
  };

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  function handleChangeAssessmentTemplateId(value: number) {
    setFilter((prev) => ({ ...prev, assessmentTemplateId: value }));
  }

  useEffect(() => {
    fetchItems();
  }, [keywords, filter]);

  return (
    <>
      <Space>
        <Select
          placeholder={
            <FormattedMessage id="AssessmentAssessmentStaticProgressLabel" />
          }
          defaultValue={filter.isProgessComplete}
          disabled
          style={{ width: 200 }}
          allowClear={true}
        >
          <Option value={true}>
            {
              <FormattedMessage id="ClientGroupAssessmentContentProgressOption1Label" />
            }
          </Option>
          <Option value={false}>
            {
              <FormattedMessage id="ClientGroupAssessmentContentProgressOption2Label" />
            }
          </Option>
        </Select>
        <Select
          placeholder={
            <FormattedMessage id="AssessmentAssessmentStaticNameLabel" />
          }
          onChange={handleChangeAssessmentTemplateId}
          loading={assessmentOptionItems.loading}
          style={{ width: 200 }}
          allowClear={true}
        >
          {assessmentOptionItems.items.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.alias}
              </Option>
            );
          })}
        </Select>
        {auth.can(ACTION.Download, "BulkGenerateReport") ? (
          <Button
            type="primary"
            onClick={handleOpenModalBulkGenerate}
            disabled={
              selectedRowKeys.length === 0 ||
              filter.assessmentTemplateId === undefined
            }
          >
            <FormattedMessage id="ClientGroupAssessmentContentBulkGenerateButton" />
          </Button>
        ) : null}
      </Space>
      <Table
        scroll={{ x: "max-content" }}
        bordered
        // rowSelection={rowSelection}
        rowSelection={{
          type: "checkbox",
          preserveSelectedRowKeys: true,
          ...rowSelection,
        }}
        dataSource={[...state.items]}
        columns={columns}
        rowKey={"id"}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AssessmentReportBulkGenerateTable;
